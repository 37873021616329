import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import invariant from 'tiny-invariant';

import { useAppDispatch } from 'app/hooks';
import {
  SortableContainer,
  SortableItemType,
  SortableList,
  SortableListInfo,
} from 'components/sortables/Sortable';
import { IBlock } from 'features/block/blockAPI';
import {
  DialogueStateProps,
  StateMan,
  mapDialogueStateToProps,
} from 'features/dialogue/dialogueSlice';
import { IListItem } from 'features/list/listItemAPI';
import { patchListItemAsync } from 'features/list/listItemSlice';
import { UserStateProps, mapUserStateToProps } from 'features/user/userSlice';

import {
  BaseEventPayload,
  ElementDragType,
} from '@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types';
import { useDialogueContext } from 'components/dialogues/DialogueView';
import { subtractPos, xyOrigin, xyPosition } from 'helpers/positions';
import { ListItem, ListItemProps } from './List';
import './ListCanvas.scss';

type ListCanvasProps = {
  block: IBlock;
  listInfo: SortableListInfo;
  showLikes: boolean;
  showColors: boolean;
  showAttribution: boolean;
  lastVisited: Date | null;
  stateMan: StateMan;
};

const UnconnectedListCanvas = (
  props: ListCanvasProps & DialogueStateProps & UserStateProps
) => {
  // const { listInfo } = props;
  const {
    block,
    listInfo,
    showLikes,
    showColors,
    showAttribution,
    lastVisited,
    stateMan,
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  // const dispatch = useAppDispatch();
  const { reorderCanvasItems } = useDialogueContext();
  const [listItemPositions, setListItemPositions] = useState<
    Record<number, { x: number; y: number }>
  >({});
  const [usedPositions] = useState<Set<number>>(new Set());

  return (
    <SortableContainer reorder={reorderCanvasItems} ref={ref}>
      <SortableList
        className="list_canvas"
        key={listInfo.id}
        listInfo={listInfo}
        accepting={[SortableItemType.Item, SortableItemType.Message]}
        copySource={!!block.locked}
        disabled={!!block.locked}
        // gap={8}
        containerRef={ref}
        // TODO: use this as client to determine position, instead of item parent
        // and adjust css for sortablewrapper
        blockId={block.id}
      >
        {(listInfo.items as IListItem[]).map((listItem, i) => {
          return (
            <ListCanvasItem
              key={listItem.id}
              listItem={listItem}
              index={i}
              listInfo={listInfo}
              canPin={props.userCanEdit(props.dialogue)}
              noDropIndicator={true}
              withHandle={false}
              containerRef={ref}
              block={block}
              showLikes={showLikes}
              showColors={showColors}
              showAttribution={showAttribution}
              lastVisited={lastVisited}
              stateMan={stateMan}
              listItemPositions={listItemPositions}
              usedPositions={usedPositions}
            />
          );
        })}
      </SortableList>
    </SortableContainer>
  );
};

const ListCanvas = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedListCanvas)
);

type ListCanvasItemProps = ListItemProps & {
  listItemPositions: Record<number, { x: number; y: number }>;
  usedPositions: Set<number>;
};

const UnconnectedListCanvasItem = (
  props: ListCanvasItemProps & DialogueStateProps & UserStateProps
) => {
  const { listItem, listInfo, listItemPositions, usedPositions } = props;
  const [position, setPosition] = useState<xyPosition>(xyOrigin);
  const dispatch = useAppDispatch();

  const id = listItem.id;
  invariant(id);
  const coordinate_x = listItem.coordinate_x;
  const coordinate_y = listItem.coordinate_y;
  let listItemPosition = listItemPositions[id] || {
    x: coordinate_x,
    y: coordinate_y,
  };
  if (coordinate_x === 0 && coordinate_y === 0) {
    let newCoordinateY = 0;
    while (usedPositions.has(newCoordinateY)) {
      newCoordinateY += 100;
    }
    // Add the new coordinate to the set
    usedPositions.add(newCoordinateY);
    // Only dispatch if the coordinates have actually changed
    if (coordinate_y !== newCoordinateY) {
      dispatch(
        patchListItemAsync({
          data: {
            id: listItem.id,
            coordinate_x: 0,
            coordinate_y: newCoordinateY,
          },
          stateMan: props.stateMan,
        })
      );
    }
  } else if (id) {
    listItemPosition = listItemPositions[id] || {
      x: coordinate_x,
      y: coordinate_y,
    };
  }

  function handleDrag(args: BaseEventPayload<ElementDragType>) {
    const delta =
      subtractPos(
        {
          x: args.location.current.input.clientX,
          y: args.location.current.input.clientY,
        },
        {
          x: args.location.initial.input.clientX,
          y: args.location.initial.input.clientY,
        }
      ) ?? xyOrigin;
    setPosition({
      x: delta.x,
      y: delta.y,
    });
  }

  return (
    <>
      <ListItem
        {...props}
        onDrag={handleDrag}
        style={{
          position: 'absolute',
          left: listItemPosition.x,
          top: listItemPosition.y,
          pointerEvents: 'all', // Allow pointer events for the list items themselves
        }}
      />
      {position?.x ?? '-'},{position?.y ?? '-'}
    </>
  );
};

const ListCanvasItem = connect(mapUserStateToProps)(
  connect(mapDialogueStateToProps)(UnconnectedListCanvasItem)
);

export default ListCanvas;
